import React from "react";
import StreamContainerFetcher from "./components/StreamContainerFetcher";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";

library.add(faCommentAlt, faPlayCircle);

const App: React.FC = () => {
  return (
    <div className="App">
      <StreamContainerFetcher />
    </div>
  );
};

export default App;
