import React, { useState, useEffect } from "react";
import StreamContainer from "./StreamContainer";
import { LiveStream } from "./Stream";

function StreamContainerFetcher() {
  const [data, setData] = useState<{
    streams: LiveStream[];
    isFetching: boolean;
  }>({ streams: [], isFetching: false });

  useEffect(() => {
    // TODO: simplify with https://www.robinwieruch.de/react-hooks-fetch-data
    const fetchStreams = async () => {
      try {
        setData({ streams: [], isFetching: true });

        const twitchStreams = await fetchTwitchStreams();
        console.log("streams live twitch: ", twitchStreams.length);

        let streams = [...twitchStreams];
        streams.sort((a, b) => b.viewers - a.viewers);

        setData({ streams: streams, isFetching: false });
      } catch (e) {
        console.log(e);
        setData({ streams: [], isFetching: false });
      }
    };
    fetchStreams();
  }, []);

  return (
    <StreamContainer streams={data.streams} isFetching={data.isFetching} />
  );
}

async function fetchTwitchStreams() {
  // TODO: We are reusing queries defined by twitch backend, which might
  // change and break. Probably want to do this in a more stable way, but
  // not now because idgaf
  console.log("Making query -- if this breaks, its probably because sha256Hash changed")
  const response = await fetch(
    "https://gql.twitch.tv/gql#origin=twilight",
    {
      headers: {
        Accept: "application/vnd.twitchtv.v5+json",
        Authorization: "OAuth e1monrywpflvpmuhu7c3d0v9hhv7j1",
        "Client-Id": "kimne78kx3ncx6brgo4mv6wki5h1ko",
      },
      method: "POST",
      body: JSON.stringify([
        {
          operationName: 'FollowingLive_CurrentUser',
          variables: { limit: 50 },
          extensions: {
            persistedQuery: {
              version: 1,
              sha256Hash: 'cc3322d2359c4e56f6df5708a5b94f7db4828fa7acd0eb204fdb5667661cb51e'
            }
          }
        }
      ])
    }
  );
  const myJson = await response.json();

  return myJson[0].data.currentUser.followedLiveUsers.edges.map(toStreamTwitch);
}

function toStreamTwitch(res: any): LiveStream {
  let node = res.node;
  return {
    site: "twitch",
    name: node.displayName,
    channel: node.login,
    viewers: node.stream.viewersCount,
    game: node.stream?.game?.displayName ?? "👻 Unknown",
    title: node.stream.title,
    preview: node.stream.previewImageURL,
  };
}


export default StreamContainerFetcher;
