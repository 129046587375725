import React from "react";
import Stream, { LiveStream } from "./Stream";
import "./StreamContainer.css";

type StreamContainerProps = {
  streams: LiveStream[];
  isFetching: boolean;
};

const StreamContainer: React.FC<StreamContainerProps> = ({ streams }) => {
  return (
    <div className="StreamContainer columns is-centered">
      <div className="column is-two-thirds">
        {streams.map((stream: any, index: number) => (
          <Stream
            key={index}
            stream={stream}
            position={getPosition(index, streams.length)}
          />
        ))}
      </div>
    </div>
  );
};

function getPosition(index: number, length: number) {
  if (index === 0) {
    return "first";
  } else if (index === length - 1) {
    return "last";
  }

  return "middle";
}

export default StreamContainer;
