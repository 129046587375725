import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Stream.css";

export type LiveStream = {
  name: string;
  channel: string;
  site: string;
  game: string;
  viewers: number;
  title: string;
  preview: string;
};

const TWITCH_URL = "http://twitch.tv/:channel";
// Render the same URL as normal, as                                                     
// there is no value to mobile view since                                                
// they added ads  
const TWITCH_MOBILE_URL = "http://twitch.tv/:channel";
const TWITCH_CHAT_URL = "http://twitch.tv/:channel/chat/popout";

const createChannelUrl = (
  channel: string,
  site: string,
  mobile: boolean = false
) => {
  // TODO: Extract these to enums/constants file
  switch (site) {
    case "twitch":
      if (mobile) {
        return TWITCH_MOBILE_URL.replace(":channel", channel);
      }
      return TWITCH_URL.replace(":channel", channel);
    default:
      return TWITCH_URL.replace(":channel", channel);
  }
};

// IINA url parameters: https://github.com/iina/iina/blob/f9b23d4c75f3af26ee010d00201d94b679664352/iina/AppDelegate.swift#L335-L348_
const createIinaUrl = (channel: string) => {
  return `twitch://open?channel=${channel}`;
};

const createChatUrl = (channel: string, site: string) => {
  // TODO: Extract these to enums/constants file
  switch (site) {
    case "twitch":
      return TWITCH_CHAT_URL.replace(":channel", channel);
    default:
      return TWITCH_CHAT_URL.replace(":channel", channel);
  }
};

type StreamProps = {
  stream: LiveStream;
  position: string;
};

const Stream: React.FC<StreamProps> = ({ stream, position }) => {
  const channel = stream.channel;
  const playUrl = createChannelUrl(channel, stream.site);
  const playMobileUrl = createChannelUrl(channel, stream.site, true);
  const iinaUrl = createIinaUrl(channel);
  const chatUrl = createChatUrl(channel, stream.site);
  const game = trimText(stream.game, 32);

  return (
    <div className="Stream box stream-container">
      <div className="columns is-vcentered">
        <a href={playUrl} className="column is-narrow is-one-quarter">
          <figure className="image is-16by9 img-preview">
            <img alt="preview" src={stream.preview} />
          </figure>
        </a>

        <a href={playMobileUrl} className="column is-two-thirds has-text-black">
          <div className="column is-paddingless is-size-6">{stream.name}</div>
          <div className="column is-paddingless is-size-6 has-text-weight-semibold">
            {stream.title}
          </div>
          <div className="column is-paddingless is-size-7">
            {game} • {stream.viewers}
          </div>
        </a>
        <div className="column is-paddingless">
          <a
            href={iinaUrl}
            className="button is-link is-light has-background-white"
          >
            <span className="icon is-large ">
              <FontAwesomeIcon icon={["fas", "play-circle"]} size="2x" />
            </span>
          </a>
          <a
            href={chatUrl}
            className="button is-light has-background-white"
            onClick={(event) => {
              popoutChat(chatUrl);
              event.preventDefault();
            }}
          >
            <span className="icon is-medium">
              <FontAwesomeIcon icon={["far", "comment-alt"]} />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

const popoutChat = (url: string) => {
  window.open(url, "MyWindow", "width=350,height=600");
  return false;
};

const trimText = (text: string, length: number) => {
  let trimmed =
    text.length > length ? text.substring(0, length - 3) + "..." : text;
  return trimmed;
};

export default Stream;
